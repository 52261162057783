// -----------------------------------//

///////////// --- DOWNLOAD TO /INC/ ... FROM CMS_DEFAULT: --- //

// TSTYLES, THEADER, TFOOTER, SUBNEWSLATEST, SUBDIARYLATEST, JAVASCRIPT + JS FOLDER

//////////// --- ON INTEGRATION, REPLACE --- //

// --------- School Name Here --------- //

//////////// --- WITH --- //

// <%=Session("SchoolName")%> //

// Fonts

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

$body-font: 'Montserrat',
    sans-serif;
$headings-font: 'Montserrat',
    sans-serif;

// ------------------------------------------------------ //
// FIND / REPLACE #2E3192, #111, #EACC1C, lighten(#2E3192, 9%), darken(#2E3192, 5%), darken(#2E3192, 5%), #2E3192
// ------------------------------------------------------ //

// Main elements
$body-bg: #fff;
$main-links-colour: deepskyblue;

// Header
$nav-bg: #2E3192;
$nav-hover: #fff;
$nav-submenu-hover: lighten(#2E3192, 13%);
$search-bg: #2E3192;

// General Elemnts
$main-box-colour: lighten(#2E3192, 9%);

// Home boxes
$box-1: #2E3192;
$box-2: lighten(#2E3192, 13%);
$box-3: #2E3192;
$box-4: lighten(#2E3192, 13%);

// News
$news-bg: #f6fae7;
$news-border: lighten(#2E3192, 9%);
$news-hover: lighten(#2E3192, 9%);

// Diary
$diary-bg: #2E3192;
$diary-border: darken(#2E3192, 5%);

// Related page
$related-pages-border: #2E3192;
$related-pages-bg: darken(#2E3192, 5%);

// Footer
$footer-links: #EACC1C;
$footer-bg: #2E3192;

// -----------------------------------//
// ELEMENTS COLOURS
// -----------------------------------//

// Grid and List view styles
$list-grid-tooltip-bg: #2E3192;

// News list inside page colours
$little-date-line: #2E3192;
$news-list-bg: #efefef;

// Subscribe
$subscribe-link-colour: #fff;
$subscribe-bg-colour: #2E3192;

// Diary list inside page colours
$diary-date-bg: #2E3192;
$diary-list-bg: #efefef;
$diary-even-row-bg: #ececec;
$add-to-calendar-bg: #2E3192;

// Auto List
$auto-list-bg: #f7f7f7;
$auto-list-border-colour: #dedede;

// Gallery
$gallery-bg: #2E3192;

// -----------------------------------//
// SEARCH RESULTS
// -----------------------------------//

$search-highlight-background-color: #2E3192;
$search-highlight-color: #fff;