// -----------------------------------//
// GRID AND LIST BUTTONS
// -----------------------------------//

h2.ListingHead {
	background: #222;
}

//Buttons wrapper
.box-grid-list {
	display: block;
	text-align: right;
	margin: 0 0 20px 0;

	//Grid button
	#add-grid {
		position: relative;

		//Grid label tooltip
		&:before {
			content: "Grid View";
			background: $list-grid-tooltip-bg;
			color: #fff;
			position: absolute;
			top: -25px;
			left: -23px;
			opacity: 0;
			width: 70px;
			font-size: 10px;
			text-transform: uppercase;
			text-align: center;
			padding: 2px 0;
			border-radius: 2px;
		}

		//Hover
		&:hover {
			&:before {
				opacity: 1;
			}
		}
	}

	//List Button
	#add-list {
		position: relative;

		//List label tooltip
		&:before {
			content: "List View";
			background: $list-grid-tooltip-bg;
			color: #fff;
			position: absolute;
			top: -25px;
			left: -20px;
			opacity: 0;
			width: 70px;
			font-size: 10px;
			text-transform: uppercase;
			text-align: center;
			padding: 2px 0;
			border-radius: 2px;
		}

		//Hover
		&:hover {
			&:before {
				opacity: 1;
			}
		}
	}

	// General Button style
	a {
		opacity: .5;
		cursor: pointer;
		margin: 0 0 0 5px;
	}

	//Active stage
	a.active {
		opacity: 1 !important;
	}
}

// -----------------------------------//
// NEWS INSIDE PAGE LISTING
// -----------------------------------//

//Subscribe button
h3.news-subscribe {
	background-image: url(/i/icons/rss-white.png);
	background-repeat: no-repeat;
	border-radius: 5px;
	background-position: 10px 8px;
	padding-left: 35px;
	background-size: 15px;
	background-color: $subscribe-bg-colour;

	a {
		color: $subscribe-link-colour;
	}

	@media only screen and (max-width: 990px) {
		font-size: 15px;
	}
}

ul#NewsListing {
	padding: 0;

	li {
		background: $news-list-bg;
		border-bottom: 1px solid #d2d2d2;
		padding: 20px; 
		box-sizing: border-box;

		//News text box
		.text-box {
			display: inline-block;
			vertical-align: top;
			width: 60%;

			//Title
			span.title {
				display: block;
				font-size: 18px;
				font-weight: bold;
			}

			//Page overview
			span.page-overview {
				display: block;
				font-size: 15px;
				color: #929292;
			}

			//Date
			span.date {
				display: block;
				text-transform: uppercase;
				font-weight: bold;
				font-size: 10px;
				position: relative;
				padding: 0 0 0 8px;
				margin: 0 0 10px 0;
				letter-spacing: 0.1em;

				//Little date line
				&:before {
					content: "";
					width: 1px;
					height: 8px;
					position: absolute;
					left: 0;
					top: 3px;
					background: $little-date-line;
				}
			}
		}
	}

	@media only screen and (max-width: 990px) {

		li {
			.text-box {
				width: 100% !important;

				span.title {
					font-size: 14px;
					line-height: 120%;
					margin: 0 0 10px 0;
				}

				span.page-overview {
					font-size: 11px;
					line-height: 140%;
				}
			}

			.news-list-img {
				display: none;
			}
		}
	}
}


//List view
ul.list-view {
	li {
		display: block;
		background: none;
		margin: 0 0 20px 0;
		padding: 25px 30px;

		//News image
		.news-list-img {
			width: 40%;
			height: 150px;
			margin: -24px 25px -30px -30px;
			display: inline-block;
			background-size: cover;
		}
	}
}

//Grid View
ul.grid-view {
	li {
		display: inline-block;
		width: calc((100% - 164px) / 2);
		min-height: 290px;
		background: none;
		margin: 0 0 50px 0;
		padding: 25px 30px;
		vertical-align: top;

		//For mobile
		@media only screen and (max-width: 990px) {
			width: 35%;
			padding: 10px 5%;
			min-height: 200px;
		}

		//News image
		.news-list-img {
			display: block;
			width: calc(100% + 60px);
			height: 150px;
			margin: -25px 0 20px -30px;
			background-size: cover;
		}

		//Text box
		.text-box {
			display: block;
			width: 100% !important;
		}

		//Float EVEN columns
		&:nth-of-type(even) {
			float: right;
		}
	}
}

// -----------------------------------//
// DIARY DATES INSIDE PAGE LISTING
// -----------------------------------//

h3.adding-to-calendar {
	border-radius: 5px;
	color: #fff;
	background: $add-to-calendar-bg;
}

ul#DiaryListing {
	margin: 0 0 0 0;
	padding: 0;

	li {
		display: block;
		background: none;
		margin: 0 0 10px 0;
		padding: 15px 20px;
		background: $diary-list-bg;
		position: relative;
		border-bottom: 1px solid #ccc;

		//STRIPED ROW
		&:nth-of-type(even) {
			background: $diary-even-row-bg
		}

		// STYLES FOR MIXED DATE
		.mix-date {
			width: 130px;
			height: 60px;
			background: $diary-date-bg;
			color: #fff;
			display: inline-block;
			text-align: center;
			position: relative;
			text-transform: uppercase;
			font-size: 11px;
			margin: 0 20px 0 0;
			float: left;

			p {
				width: 50%;
				height: 100%;
				float: left;
				margin: 0;

				&:last-of-type {
					background: rgba(0, 0, 0, .4);
				}
			}

			span {
				display: block;
				font-size: 25px;
			}

			span.to {
				width: 20px;
				height: 20px;
				border-radius: 50%;
				position: absolute;
				top: 50%;
				left: 50%;
				text-align: center;
				line-height: 20px;
				margin-left: -10px;
				margin-top: -15px;
				font-size: 9px;
				background: $diary-date-bg;
				color: #fff;
			}
		}

		// STYLES FOR SINGLE DATE
		.diary-date {
			width: 130px;
			height: 60px;
			background: $diary-date-bg;
			color: #fff;
			display: inline-block;
			text-align: center;
			position: relative;
			text-transform: uppercase;
			font-size: 11px;
			margin: 0 20px 0 0;
			float: left;

			span {
				display: block;
				font-size: 25px;
			}
		}

		//HOUR
		.hour {
			display: inline-block;
			position: absolute;
			top: 10px;
			left: 170px;
			font-size: 12px;
			background: url(/i/icons/ic-hour.png) no-repeat 0 2px;
			padding: 0 0 0 18px;
			background-size: contain;
			background-size: 13px;
		}

		//TITLE
		.diary-title {
			width: 70%;
			height: 60px;
			padding: 0 0 0 0;
			display: table;
			vertical-align: top;
			font-weight: bold;

			a,
			span {
				display: table-cell;
				vertical-align: middle;
			}
		}

		//SHARE
		.add-share {
			position: absolute;
			top: 50%;
			right: 10px;
			width: 30px;
			display: inline-block;
			text-align: center;
			margin-top: -23px;

			a {
				display: inline-block;

				img {
					margin: 0;
				}
			}
		}
	}
}

// -----------------------------------//
// AUTO LIST
// -----------------------------------//

ul.auto-list {
	li {
		display: block;
		background-color: $auto-list-bg;
		border: 1px solid $auto-list-border-colour;
		padding: 10px 10px 10px 40px;
		background-position: 10px 12px;
		margin: 0 0 5px 0;
		background-repeat: no-repeat;
	}
}

//List view
ul.list-view-list {
	li {
		display: block;
		margin: 0 0 20px 0;
		padding: 10px 0 10px 55px;
	}
}

//Grid View
ul.grid-view-list {
	li {
		width: calc((100% / 3) - 90px);
		vertical-align: top;
		height: calc(150px - 60px);
		padding: 30px;
		margin: 0 25px 25px 0;
		display: inline-block;
		background-position: 0 0;
	}
}

img.ListingIcon {
	margin: 0 10px 0 0;
	float: left;
}

// -----------------------------------//
// SLIDESHOW (GALLERIES)
// -----------------------------------//

.SScatHolder {
	width: 500px;
	height: 304px;
	color: #FFF;
	background-image: url(/admin/i/GalleryGroupPlaceholder.jpg);
	background-repeat: no-repeat;
	background-position: 0 0;
	display: block;
}

.SSHolder {
	width: 500px;
	height: 375px;
	color: #FFF;
	background-image: url(/admin/i/GalleryPlaceholder.jpg);
	background-repeat: no-repeat;
	background-position: 0 0;
}

.SSWrapper {
	border: 1px solid #666;
	margin: 5px 0 10px 0;
	padding: 0;
	height: 375px;
	vertical-align: middle;
	width: 500px;
	background-color: #FFF;
}

// Tidy up

#ss_gallery_holder {
	background: #fff;
	border: 1px solid #dcdcdc !important;
	margin-bottom: 40px !important;
}

#ss_gallery_holder h3 {
	margin: 0 0 20px 0 !important;
	padding: 10px 20px 0px 20px;
	font-size: 23px !important;
}


#SSlistblock {
	margin: 0;
	padding: 0;

	.singlegallery {
		width: 48%;
		margin: 0 0 30px 0;
		float: left;
		position: relative;
		max-height: 330px;
		overflow: hidden;

		&:nth-of-type(even) {
			float: right;
		}

		a.thumblink {
			display: inline-block;
			width: 100%;
			background: $gallery-bg;
			color: #fff;
			text-transform: uppercase;

			&:hover {
				.thumb-wrap {
					transition: (all 0.3s ease-in-out);
					opacity: .3;
				}
			}

			.thumb-wrap {
				transition: (all 0.3s ease-in-out);
				opacity: .5;
				min-height: 340px;
				background-size: cover;

				img.thumb {
					width: 100%;
					height: auto;
				}
			}

			.summary {
				width: 100%;
				display: block;
				position: absolute;
				top: 50%;
				left: 0;
				text-align: center;
				margin-top: -20px;

				.title {
					margin: 5px 0;
					font-size: 18px;
					font-weight: bold;
				}

				.count {
					font-size: 12px;
					display: block;
					margin: 0 0 5px 0;
				}

				.description {
					font-size: 12px;
					display: block;
					margin: 0 0 5px 0;
				}
			}
		}
	}
}