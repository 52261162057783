.boxmenuright,
.boxmenuleft {
	border: 2px solid #CCC;
	float: right;
	padding: 10px 10px 6px 10px;
	margin: 0 0 10px 20px;
	width: 206px;
}

.boxmenuleft {
	float: left;
	margin: 0 10px 0 20px;
}

.boxmenuright h2,
.boxmenuleft h2 {
	border-bottom: 1px dotted #CCC;
	font-weight: normal;
	font-size: 1.2em;
	margin: 0 0 10px 0;
	padding: 0 0 5px 0;
}

.boxmenuright li,
.boxmenuleft li {
	background-image: url(/i/icons/page.gif)
}

.boxout,
.boxoutright,
.boxoutpurple,
.boxoutpurpleright,
.boxoutgreen,
.boxoutgreenright,
.boxoutblue,
.boxoutblueright {
	float: left;
	background-color: #FF5924;
	display: inline;
	padding: 10px;
	margin: 2px 20px 1px 0;
	width: 230px;
	color: #FFF;
	font-size: 1.2em;
}

.boxoutpurple,
.boxoutpurpleright {
	background-color: #663d7f;
}

.boxoutgreen,
.boxoutgreenright {
	background-color: #009b66;
}

.boxoutblue,
.boxoutblueright {
	background-color: #00639c;
}

.boxoutright,
.boxoutpurpleright,
.boxoutgreenright,
.boxoutblueright {
	float: right;
	margin: 2px 0 1px 20px;
}

.boxout p,
.boxoutright p,
.boxoutpurple p,
.boxoutpurpleright p,
.boxoutgreen p,
.boxoutgreenright p,
.boxoutblue p,
.boxoutblueright p {
	margin: 0;
	color: #FFF;
}

div.boxout a,
div.boxoutright a,
div.boxoutpurple a,
div.boxoutpurpleright a,
div.boxoutgreen a,
div.boxoutgreenright a,
div.boxoutblue a,
div.boxoutblueright a {
	color: #FFF
}

div.boxout a:hover,
div.boxoutright a:hover,
div.boxoutpurple a:hover,
div.boxoutpurpleright a:hover,
div.boxoutgreen a:hover,
div.boxoutgreenright a:hover,
div.boxoutblue a:hover,
div.boxoutblueright a:hover {
	background-color: none;
	background: transparent;
	text-decoration: underline;
}

.button {
	background-color: #666;
	color: #FFF;
	border: 1px solid #333;
	padding: 1px 2px;
	margin: 10px 0;
	font-weight: bold;
}

.floatleft {
	float: left;
	margin: 0 10px 2px 0;
}

.floatright {
	float: right;
	margin: 0 0 2px 10px;
}

.greytext {
	color: #CCC;
}

.imageFullWidthborder {
	border: 1px solid #666;
	margin: 0 0 10px 0;
	display: block;
}

.imageleft {
	float: left;
	margin: 0 17px 1px 0;
}

.imageleftborder {
	float: left;
	margin: 0 17px 1px 0;
	border: 1px solid #CCC;
}

.imageright,
.imageRight {
	float: right;
	margin: 0 0 1px 17px;
}

.imagerightborder,
.imageRightBorder {
	float: right;
	margin: 0 0 1px 17px;
	border: 1px solid #CCC;
}

div.imageleftborder {
	float: left;
	margin: 0 17px 1px 0;
	display: inline;
}

div.imagerightborder,
div.imageRightBorder {
	float: right;
	margin: 0 0 1px 17px;
	display: inline;
}

div.imagerightborder img,
div.imageRightBorder img {}

div.imagerightborder p,
div.imageleftborder p,
div.imageRightBorder p,
div.imageLeftBorder p {
	margin: 0;
	padding: 1px 5px;
	background-color: #CCC;
	font-size: 0.9em;
}

img.imagerightborder,
img.imageRightBorder {
	float: right;
	border: 1px solid #CCC;
	margin: 0 0 1px 17px;
}

.message,
p.message {
	padding: 10px;
	background-color: #CC0033;
	color: #FFF;
	border: 1px solid #990033;
}

.newsflash {
	color: #661C21;
	padding: 10px 10px 5px 10px;
	border: 5px solid #661C21;
	background: #F3D6D8;
	background-image: url(/i/design/lite/bg_NewsFlash.gif);
	background-repeat: no-repeat;
	background-position: right 0px;
}

.newsflash h2 {
	color: #661C21;
	margin: 0 0 8px 0;
	padding: 0 0 8px 0;
	font-size: 1.2em;
	border-bottom: 1px solid #CB3D47;
	border-top: 0;
	border-right: 0;
	border-left: 0;
}

.newsflash p {
	color: #661C21;
	margin: 5px 0;
}

.newsflash a {
	text-decoration: underline;
}

blockquote,
q {
	quotes: none;
}

blockquote {
	margin: 12px 0;
	background: $blockquote-background-color;
	padding: 10px 50px;
	position: relative;
	font-family: Georgia, serif;
	color: $blockquote-color;
	border: 1px solid $blockquote-border-color;
	font-size: $blockquote-font-size;
	border-radius: 5px;
}

blockquote:before,
blockquote:after {
	content: "\201C";
	position: absolute;
	font-size: 60px;
	line-height: 1;
	color: $blockquote-speech-mark-color;
	font-style: normal;
}

blockquote:before {
	top: 10px;
	left: 10px;
}

blockquote:after {
	content: "\201D";
	right: 10px;
	bottom: -20px;
}

.tableborder {
	border-bottom: 1px solid $table-border-color;
	border-left: 1px solid $table-border-color;
	margin: 20px 0;
}

.tableborder td,
.tableborder th {
	padding: 10px;
	margin: 0;
	font-weight: normal;
	border-right: 1px solid $table-border-color;
	border-top: 1px solid $table-border-color;
	vertical-align: top;
	text-align: left;
}

.tableborder td p {
	margin: 0;
}

.tableborder td.tablecellhighlight,
.tableborder th.tablecellhighlight,
.tablecellhighlight {
	background-color: $table-highlight-background-color;
	color: $table-highlight-color;
	font-weight: $table-highlight-font-weight;
}

.tablenoborderpadded {
	border: 0;
	margin: 10px 0 0 -10px;
}

.tablenoborderpadded td,
.tablenoborderPadded th {
	padding: 10px 0 10px 10px;
	margin: 0;
	font-weight: normal;
	border: 0;
	vertical-align: top;
	text-align: left;
}

.tablenoborderpadded td p {
	margin: 0;
}

.tablenoborderpadded td.tablecellhighlight,
.tablenoborderpadded th.tablecellhighlight {
	background-color: $table-highlight-background-color;
	color: $table-highlight-color;
	font-weight: $table-highlight-font-weight;
}

.search-table {
	border-bottom: none;
	border-left: none;
	margin: 20px 0;
}

.search-table td,
.tableborder th {
	padding: 10px;
	margin: 0;
	font-weight: normal;
	border-right: none;
	border-top: none;
	vertical-align: top;
	text-align: left;
}

.search-table tr {
	&:nth-of-type(odd) {
		background: $search-odd-tr-background;
	}
}

.search-table td p {
	margin: 0;
}

.search-table td.tablecellhighlight,
.search-table th.tablecellhighlight,
.tablecellhighlight {
	padding: 8px 10px;
	font-size: $search-highlight-font-size;
	background-color: $search-highlight-background-color;
	color: $search-highlight-color;
	font-weight: $search-highlight-font-weight;
}